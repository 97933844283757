;(function(window) {
	const el = document.querySelectorAll('.lash-variant-picker');
	
	if (el.length) {
		el.forEach((pickerItem) => {
			const variantData = JSON.parse(pickerItem.querySelector('[type="application/json"]').textContent)

			const buttons = pickerItem.querySelectorAll('[data-for]')
			buttons.forEach((button) => {
				button.addEventListener('click', ({ currentTarget }) => {
					pickerItem.querySelector(`label[for='${currentTarget.dataset.for}']`).click()
				})
			})
			pickerItem.addEventListener('change', (evt) => {
				const radioVal = evt.currentTarget.querySelector('input[type="radio"]:checked')
				const variantId = radioVal.value
				
				if (variantId && variantData) {
					// const selectedVariant = variantData.find((item) => item.id == radioVal.value)
					// console.log(selectedVariant)

					// Update form variant
					document.querySelectorAll('input.product-variant-id').forEach((el) => {
						el.value = variantId
					})

					// Update price
					const productInfoEl = document.querySelector('.product__info-container')
					
					fetch(
						`${productInfoEl.dataset.url}?variant=${variantId}&section_id=${
							productInfoEl.dataset.originalSection ? productInfoEl.dataset.originalSection : productInfoEl.dataset.section
						}`
					)
						.then((response) => response.text())
						.then((responseText) => {
							// prevent unnecessary ui changes from abandoned selections
							// if (this.currentVariant.id !== requestedVariantId) return;
			
							const html = new DOMParser().parseFromString(responseText, 'text/html');
							const destination = document.getElementById(`price-${productInfoEl.dataset.section}`);
							const source = html.getElementById(
								`price-${productInfoEl.dataset.originalSection ? productInfoEl.dataset.originalSection : productInfoEl.dataset.section}`
							);
							const skuSource = html.getElementById(
								`Sku-${productInfoEl.dataset.originalSection ? productInfoEl.dataset.originalSection : productInfoEl.dataset.section}`
							);
							const skuDestination = document.getElementById(`Sku-${productInfoEl.dataset.section}`);
							const inventorySource = html.getElementById(
								`Inventory-${productInfoEl.dataset.originalSection ? productInfoEl.dataset.originalSection : productInfoEl.dataset.section}`
							);
							const inventoryDestination = document.getElementById(`Inventory-${productInfoEl.dataset.section}`);
			
							if (source && destination) destination.innerHTML = source.innerHTML;
							if (inventorySource && inventoryDestination) inventoryDestination.innerHTML = inventorySource.innerHTML;
							if (skuSource && skuDestination) {
								skuDestination.innerHTML = skuSource.innerHTML;
								skuDestination.classList.toggle('visibility-hidden', skuSource.classList.contains('visibility-hidden'));
							}
			
							const price = document.getElementById(`price-${productInfoEl.dataset.section}`);
			
							if (price) price.classList.remove('visibility-hidden');
			
							if (inventoryDestination)
								inventoryDestination.classList.toggle('visibility-hidden', inventorySource.innerText === '');
			
							// const addButtonUpdated = html.getElementById(`ProductSubmitButton-${sectionId}`);
							// this.toggleAddButton(
							// 	addButtonUpdated ? addButtonUpdated.hasAttribute('disabled') : true,
							// 	window.variantStrings.soldOut
							// );
						});					
				}
			})
		})
	}

})(window)
