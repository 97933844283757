import $ from 'jquery';
import 'slick-carousel/slick/slick';

;(function(window) {
	const galleryWrapper = document.querySelector('.product-media-gallery--slick');
	if (galleryWrapper) {
		const $slider = $(galleryWrapper).find('.product-media-gallery__slick')
		const prevArrow = galleryWrapper.querySelector('.slick-prev')
		const nextArrow = galleryWrapper.querySelector('.slick-next')
		
		$slider.slick({
			dots: true,
			arrows: true,
			appendDots: '.product-media-gallery__slick-dots',
			prevArrow, nextArrow,
		})
	}
})(window)
